import React from "react";
import { Link } from "gatsby";
import Grid from "@material-ui/core/Grid";
import { Box, Button, IconButton, Collapse } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { GatsbyImage } from "gatsby-plugin-image";

import MuiLink from "@material-ui/core/Link";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import HorizontalLogo from "../images/v2/campaigns/Horizontal-logo.png";
import Logo from "../images/v2/PC-vertical-logo.svg";
import callImg from "../images/v2/campaigns/call.svg";
import singaporeSmeBannerImg from "../images/v2/campaigns/dashboardBackgroundImg.png";
import useWidth from "../hooks/useWidth";
import dealSection from "../images/v2/aircall/aircall-image-crm.png";
import loadable from "@loadable/component";
import WhiteButton from "../components/v2/WhightButton";
import mainBannerImage from "../images/v2/aircall/main-banner2x.png";
import { conversionEventOnCall } from "./singapore-sme-v2";
import SEO from "../components/SEO";
import smeSupportLogo from "../images/v2/campaigns/smeSupportLogo.png";
import { StaticImage } from "gatsby-plugin-image";
import requestDemoBGImg from "../images/v2/campaigns/request-demo-bg.svg";
import Footer from "../components/v2/Footer/index";
import IMDA_SME_Large from "../images/v2/psg/IMDA_SME_Large.webp";
const useStyles = makeStyles((theme) => ({
  SmeStyling: {
    width: "74%",
    margin: "20px auto 0",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "10px auto 0",
    },
  },
  smeFooterText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      textAlign: "center",
    },
  },
  marginAuto: {
    margin: "0 auto",
  },
  header: {
    padding: ".65rem 0 .25rem",
  },
  backgroundWhite: {
    position: `relative`,
    backgroundColor: `#fff`,
  },

  singaporeSmeBanner: {
    position: `relative`,
    background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) -6%, #b5dbe230 100%)`,
    backgroundSize: "contain",
    width: "100%",
    paddingBottom: "20px",
    [theme.breakpoints.up(1600)]: {
      position: `relative`,
      background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) -6%, #b5dbe230 100%)`,
      backgroundSize: "contain",
      width: "100%",
      paddingBottom: "14px",
    },
  },

  singaporeSmeFromBox: {
    paddingLeft: "35px",
    paddingTop: "30px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "16px",
      paddingLeft: "0px",
    },
  },

  maxWidth: {
    maxWidth: "1280px",
    [theme.breakpoints.up(1400)]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.down("xs", "sm")]: {
      width: "100%",
      padding: "0 ",
    },
  },
  listItem: {
    paddingLeft: "0px",
  },
  listItemText: {
    "& *": {
      fontSize: 18,
      color: "#2e3f4f",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  listItemIcon: {
    color: "#fff",
    fontSize: 26,
    backgroundColor: "#15a323",
    borderRadius: "50%",
    padding: "5px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
  },
  singaporeSmeFrom: {
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 2px 5px 0 #b7b7b7",
    backgroundSize: "430px 522px",
    position: "relative",
    zIndex: "1",
    margin: "0 auto",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  singaporeSmeFromHeader: {
    background: "#2e3f4f",
    borderRadius: "10px 10px 0 0",
    padding: ".6rem .25rem",
    letterSpacing: ".32px",
    fontSize: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  img: {
    backgroundSize: "contain",
  },
  sectionimg: {
    maxWidth: "100%",
    height: "auto",
    willChange: "auto !important",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "20px,",
    },
  },
  imageBigscreen: {
    [theme.breakpoints.up("xl")]: {
      textAlign: "left",
    },
  },
  listWidth: {
    width: "50%",
  },
  colorChange: {
    color: "#ff7a59",
    fontSize: "30px",
    fontWeight: "700",
    letterSpacing: ".4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
    },
  },

  listContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column-reverse",
    },
  },
  PCBenefits: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "auto",
      margin: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "200px",
    },
    [theme.breakpoints.down("650")]: {
      marginBottom: "90px",
    },
    [theme.breakpoints.down("650")]: {
      marginBottom: "0",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "617px",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "auto",
    },
  },
  bannerSection: {
    whiteSpace: "pre-wrap",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      whiteSpace: "normal",
    },
  },
  winSalesImg: {
    // marginTop: "4px",
    // marginLeft: "8px",
    paddingRight: "84px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0px",
      paddingRight: "0px",
    },
  },
  winSalesImage: {
    width: "447.8px",
    height: "495.8px",
    marginTop: "-40px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },
  listItemContainer: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "-webkit-center",
    },
  },
  optimiseRightSection: {
    paddingTop: "75px",
    paddingLeft: "60px",
    [theme.breakpoints.down("md")]: {
      padding: "20px",
      margin: "0px",
      paddingLeft: "0",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
      margin: "0px",
    },
  },
  optimiseHeader: {
    paddingBottom: "15px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
      textAlign: "center",
      paddingBottom: "0px",
      maxWidth: "340px",
      margin: "0 auto",
    },
  },

  whitespaceNowrap: {
    whiteSpace: "prewrap",
  },

  aircallText: {
    color: "#0c8639",
  },

  copyFooter: {
    color: "#cbcccd",
    textAlign: "end",
    paddingRight: "23px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },

  requestDemoRoot: {
    background: `url(${requestDemoBGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  driveSalesText: {
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs", "sm")]: {
      width: "100%",
      padding: "0 ",
      whiteSpace: "normal",
    },
  },
  smeSupportLogo: {
    width: "100%",
    padding: "0",
    [theme.breakpoints.down("xs", "sm")]: {
      width: "100%",
      padding: "0 ",
    },
  },

  pcWebForm: {
    width: "100%",
    marginTop: "-7px",
    height: "496px",
    maxHeight: "529px",
    [theme.breakpoints.down("sm")]: {
      height: "529px",
    },
  },
  BookADemobutton: {
    textAlign: "center",
    paddingBottom: "40px",
    paddingTop: "40px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "34px",
      paddingBottom: "34px",
    },
  },

  getStatrtedButton: {
    textAlign: "center",
    // marginTop: "-50px",
    paddingTop: "40px",
    paddingBottom: "40px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "34px",
      paddingBottom: "34px",
      margin: "0px",
    },
    [theme.breakpoints.up(1920)]: {
      paddingTop: "45px",
      paddingBottom: "50px",
      margin: "0px",
    },
  },
  webformContainer: {
    height: "490px",
    maxHeight: "535px",
    [theme.breakpoints.down("sm")]: {
      height: "535px",
    },
  },
  trackConatainer: {
    paddingLeft: "45px",
  },
  trackSectionImage: {
    width: "100%",
    height: "100%",
    marginTop: "30px",
    // paddingTop: 28px;
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "auto",
      paddingTop: "0px",
      margin: "0px",
    },
    [theme.breakpoints.down(800)]: {
      width: "100%",
      height: "auto",
      paddingTop: "0px",
      margin: "0px",
    },
  },
  trackImg: {
    // margintop: "34px",
  },
  TrackHeader: {
    paddingBottom: "15px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  },
  dealSection: {
    position: `relative`,
    background: `transparent url(${dealSection}) no-repeat center`,
    backgroundSize: "contain",
    backgroundPosition: "right",
    marginRight: "-71px",
    width: "100%",
    height: "100%",
    marginLeft: "57px",
    [theme.breakpoints.down("xs")]: {
      backgroundSize: "cover",
      width: "100%",
      margin: "0px",
    },
  },
  TrackSection: {
    position: `relative`,
    background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) -6%, #b5dbe230 100%)`,
    backgroundSize: "cover",
    width: "100%",
    [theme.breakpoints.up(1600)]: {
      position: `relative`,
      background: `linear-gradient(to bottom, rgba(255, 255, 255, 0) -6%, #b5dbe230 100%)`,
      backgroundSize: "cover",
      width: "100%",
    },
  },
  WhatsAppCrm: {
    background: `linear-gradient(to bottom, #f5f9fe, #fff 58%)`,
    paddingBottom: "35px",
  },
  whatsappImg: {
    width: "59.1px",
    height: "60.1px",
    [theme.breakpoints.down("xs")]: {
      height: "35px",
      width: "35px",
      top: "-2px",
    },
  },
  aircallImage: {
    width: "59.1px",
    height: "60.1px",
    margin: "0px 7px 0 3px",
    [theme.breakpoints.down("xs")]: {
      height: "35px",
      width: "35px",
      margin: "0px",
      top: "-2px",
    },
  },

  winList: {
    textAlign: "left",
    paddingBottom: "15px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "22px",
      paddingTop: "31px",
      textAlign: "center",
      paddingBottom: "0px",
      maxWidth: "340px",
      margin: "0 auto",
    },
  },
  paddingConatiner: {
    justifyContent: "center",
    [theme.breakpoints.up(1400)]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up(1024)]: {
      paddingLeft: "31px",
    },
    [theme.breakpoints.down(1920)]: {
      justifyContent: "flex-end",
      paddingRight: "47px",
    },
    [theme.breakpoints.up(1920)]: {
      justifyContent: "flex-end",
      paddingRight: "52px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0px",
      padding: "0px",
    },
  },

  listpadding: {
    marginLeft: "-15px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: " 0px",
    },
  },

  PCBenefitsImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down(1920)]: {
      justifyContent: "flex-start",
      paddingRight: "24px",
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: "24px",
    },
  },
  aircallBlock: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexDirection: "column",
    },
  },

  bannerImage: {
    [theme.breakpoints.down(800)]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

const security = `Chat from a single account, through multiple devices.
Send quick replies with customised message templates.
Blast promotional messages to multiple contacts.
Send product catalogues, PDFs, videos, and other media files.
Provide extensive support to customers via WhatsApp groups.
Assist customers 24/7 with WhatsApp chatbots.`.split(`
`);

const optimise = `Make and receive calls with just a click. 
Personalise conversations with complete customer details. 
Automatically record calls, logs, and other call details.
Track conversation history with call summaries and tags.
Access and analyse your team’s call metrics in real time.
Predefine call distribution workflows to intelligently route inbound calls.`
  .split(`
`);

const TrackSectionList =
  `Capture leads from multiple platforms and build sales pipelines.
  Gain visibility of your sales funnel and focus on the appropriate sales activities.
  Visualise sales opportunities and forecast your sales and revenue.
  Automate your sales process with workflow automation.
  Integrated with multiple tools such as Mailchimp, Zoom, Xero, and more. 
  IMDA-approved CRM with 50% PSG grant.`.split(`
`);

const CompanyCarousel = loadable(() =>
  import("../components/v2/CompanyCarousel")
);
const AircallPage = () => {
  const myRef = React.useRef(null);

  const executeScroll = () => {
    myRef.current.scrollIntoView();
  };

  const classes = useStyles();

  const width = useWidth();
  const [activeCalendar, setActiveCalendar] = React.useState(0);

  React.useEffect(() => {
    const pcIframe = document.getElementById("pc-iframe");
    pcIframe.src = pcIframe.src + window.location.search;
    if (window.location.search)
      pcIframe.src = pcIframe.src + "&page_title=" + document.title;
    else pcIframe.src = pcIframe.src + "?page_title=" + document.title;
  }, []);

  return (
    <React.Fragment>
      <SEO
        canonical="/CRM with WhatsApp & Aircall Integration"
        description=" Grow your sales with Pepper Cloud CRM's WhatsApp and Aircall integration. Win your sales conversations by sending messages or making voice calls directly from CRM."
        keywords="CRM with WhatsApp & Aircall Integration | Pepper Cloud"
        pathname="/crm-with-whatsApp-&-aircall-integration"
        title=" CRM with WhatsApp & Aircall Integration | Pepper Cloud
"
      />
      <Box className={classes.header}>
        <Box component={Container}>
          <Grid alignItems="center" container justifyContent="center">
            <Grid item md={7} sm={7}>
              <Box
                alignItems="center"
                display="flex"
                justifyContent="flex-start"
                my={1}
              >
                <Box alignItems="center" display="flex">
                  <Link to="/">
                    <Box
                      alt="Pepper Cloud CRM"
                      component={"img"}
                      height={{ sm: 36, xs: 32 }[width] || 58}
                      mr={{ sm: 1, xs: 1 }[width] || 2}
                      src={HorizontalLogo}
                    />
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item md={5} sm={5}>
              <Box
                alignItems="flex-end"
                display="flex"
                justifyContent="flex-end"
                my={1}
                textAlign="right"
              >
                <HeaderTypography
                  className="header"
                  color="#2e3f4f"
                  component="h4"
                  fontSize={{ sm: 13, xs: 12 }[width] || 18}
                  fontWeight={600}
                  m={0}
                  mr={{ sm: 2, xs: 1 }[width] || 2}
                  overrideClassName
                >
                  Book a FREE Demo
                </HeaderTypography>
                <MuiLink href="tel:+6597510475" onClick={conversionEventOnCall}>
                  <Box alignItems="center" display="flex">
                    <Box
                      alt="Call to Pepper Cloud"
                      component={"img"}
                      height={{ sm: 14, xs: 14 }[width] || 20}
                      mr={{ sm: 0, xs: 0 }[width] || 1}
                      src={callImg}
                    />
                    <ParagraphTypography
                      className={classes.whitespaceNowrap}
                      color="#0291ae"
                      fontSize={{ sm: 14, xs: 12 }[width] || 20}
                      fontWeight={700}
                      m={0}
                      overrideClassName
                    >
                      +65 97510475
                    </ParagraphTypography>
                  </Box>
                </MuiLink>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Banner */}
      <Box className={classes.singaporeSmeBanner}>
        <Container>
          <Grid alignItems="flex-start" container justify="center" mt={3}>
            <Grid item md={7} sm={12}>
              <Box>
                <HeaderTypography
                  className={classes.bannerSection}
                  component="h1"
                  fontSize={{ sm: 36, xs: 34 }[width] || 41}
                  fontWeight="600"
                  lineHeight={1.25}
                  mb={4}
                  mt={3}
                  //   style={{ whiteSpace: "nowrap" }}
                  overrideClassName
                >
                  Run business with WhatsApp CRM
                </HeaderTypography>
                <ParagraphTypography
                  className={classes.smeBannerTxt}
                  color="#2e3f4f"
                  component="h2"
                  font-family="SourceSansPro"
                  fontSize={20}
                  mb={3}
                  mt={0}
                >
                  Converse with leads and customers through voice calls or
                  WhatsApp messages from within CRM system. Blast promotional
                  messages, make instant voice calls, provide customer support
                  via WhatsApp groups, and do more.
                </ParagraphTypography>

                <br />
                <Box
                  display={{ sm: "flex", xs: "flex" }[width] || "flex"}
                  alignItems={{ xl: "flex-start", lg: "center", md: "center" }}
                  className={classes.bannerImage}
                >
                  <Box
                    alt=" CRM with WhatsApp and Aircall Integration"
                    component={"img"}
                    maxHeight="470px"
                    maxWidth="100%"
                    src={mainBannerImage}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item md={5} sm={9} xs={12}>
              <Box
                className={classes.singaporeSmeFromBox}
                px={1}
                textAlign="center"
              >
                <Box className={classes.singaporeSmeFrom} ref={myRef}>
                  <HeaderTypography
                    className={classes.singaporeSmeFromHeader}
                    color="#fff"
                    component="h3"
                    fontWeight={600}
                    m={0}
                    textAlign="center"
                  >
                    Avail up to <b className={classes.colorChange}>50%</b> PSG
                    grant
                  </HeaderTypography>
                  <Box className={classes.webformContainer}>
                    <Box
                      className={classes.pcWebForm}
                      border="none"
                      component="iframe"
                      id="pc-iframe"
                      overflow="hidden"
                      referrerpolicy="unsafe-url"
                      src="https://app.peppercloud.com/form/20fd072f-ca01-4b3c-92a6-e9d3f69bf036/embed"
                      width="99%"
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "3rem 1rem",
        }}
      >
        <Box maxWidth={1015} textAlign="center">
          <Box
            onClick={() =>
              window.open(
                "https://services2.imda.gov.sg/CTOaaS/DigitalHealthCheck?utm_source=PA+vendor&utm_medium=vendor+site&utm_campaign=SN222"
              )
            }
            sx={{ cursor: "pointer" }}
            component={"img"}
            src={IMDA_SME_Large}
            alt={"IMDA Pre-approved Solution Provider"}
            maxWidth={"100%"}
            margin={0}
            width={"100%"}
            mb={0}
          />
          <Box className={classes.SmeStyling}>
            <ParagraphTypography
              color="text.secondary"
              fontSize={16}
              sx={{ padding: 0, margin: 0 }}
              textAlign="center"
              className={classes.smeFooterText}
            >
              SMEs are eligible for up to 50% Productivity Solutions Grant (PSG)
              support for the adoption of Pepper Cloud CRM solution, a
              Pre-Approved Solution under the IMDA SMEs Go Digital programme.
            </ParagraphTypography>
          </Box>
        </Box>
      </Container>
      <Grid alignItems="center" container justify="center" pb={8}>
        <Grid item md={6} sm={12}>
          <Box
            className={classes.paddingConatiner}
            display="flex"
            justifyContent={{ sm: "center", md: "center" }}
          >
            <Box>
              <HeaderTypography
                className={classes.winList}
                component="h2"
                fontWeight={600}
                marginBottom="0"
                maxWidth={566}
              >
                Win sales deals quickly with{" "}
                <StaticImage
                  alt="WhatsApp CRM"
                  className={classes.whatsappImg}
                  placeholder="blurred"
                  src="../images/v2/aircall/whatsapp-icon.png"
                />
                <span className={classes.aircallText}> WhatsApp + CRM</span>
              </HeaderTypography>
              <Box>
                <List className={classes.listpadding}>
                  {security.map((each) => (
                    <ListItem alignItems="center" key={each}>
                      <ListItemIcon>
                        <CheckIcon className={classes.listItemIcon} />
                      </ListItemIcon>
                      <ListItemText className={classes.listItemText}>
                        {each}
                      </ListItemText>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} sm={12}>
          <Box className={classes.imageBigscreen} textAlign="right">
            <StaticImage
              alt="WhatsApp Group CRM"
              className={classes.sectionimg}
              height={674}
              placeholder="blurred"
              src="../images/v2/aircall/whatsapp-crm.png"
            />
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box className={classes.BookADemobutton}>
              <Button
                className="book-demo-btn"
                color="secondary"
                onClick={executeScroll}
                size="large"
                variant="contained"
              >
                Book a Free Demo
              </Button>
            </Box>
          </Grid>
        </Container>
      </Box>

      <Box>
        <Grid className={classes.listContainer} container>
          <Grid
            className={classes.listItemContainer}
            item
            md={6}
            sm={12}
            xs={12}
          >
            <Box className={classes.PCBenefits}>
              <Box className={classes.PCBenefitsImg}>
                <StaticImage
                  alt="VoIP CRM Intergration"
                  className={classes.cycleProcessImg}
                  height={796}
                  placeholder="blurred"
                  src="../images/v2/aircall/aircall-image-crm.png"
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            alignItems="center"
            className={classes.optimiseRightSection}
            item
            md={6}
            sm={12}
            xs={12}
          >
            <Box className={classes.aircallBlock} display="flex" maxWidth={513}>
              <Box>
                <HeaderTypography
                  className={classes.optimiseHeader}
                  component="h2"
                  fontWeight={600}
                  marginBottom="0"
                  // mb={{ sm: 3, xs: 3 }[width] || 10}
                  // mt={{ md: 3 }}
                  maxWidth={587}
                >
                  Optimise sales efficiency with{" "}
                  <StaticImage
                    alt="Aircall CRM integration"
                    className={classes.aircallImage}
                    placeholder="blurred"
                    src="../images/v2/aircall/aircall-Icon.png"
                  />
                  <span className={classes.aircallText}>Aircall + CRM</span>
                </HeaderTypography>
                <Box>
                  <List className={classes.listpadding}>
                    {optimise.map((each) => (
                      <ListItem alignItems="center" key={each}>
                        <ListItemIcon>
                          <CheckIcon className={classes.listItemIcon} />
                        </ListItemIcon>
                        <ListItemText className={classes.listItemText}>
                          {each}
                        </ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box className={classes.getStatrtedButton}>
            <Button
              className="book-demo-btn"
              color="secondary"
              onClick={executeScroll}
              size="large"
              variant="contained"
            >
              Get Started
            </Button>
          </Box>
        </Grid>
      </Box>

      <Box className={classes.WhatsAppCrm}>
        <Box
          alignItems="center"
          component={Container}
          display="flex !important"
          flexDirection="column"
          mt={0}
          pt={0}
          // my={{ md: 4 }}
          textAlign="center"
        >
          <Grid alignItems="center" container justify="center" spacing={2}>
            <Grid alignItems="center" item md={6} sm={12} xs={12}>
              <HeaderTypography
                className={classes.TrackHeader}
                component="h2"
                fontWeight={600}
                marginBottom="0"
                maxWidth={506}
                textAlign="left"
              >
                Track your sales pipeline with{" "}
                <span className={classes.aircallText}>best sales CRM</span>
              </HeaderTypography>

              <List className={classes.listpadding}>
                {TrackSectionList.map((each) => (
                  <ListItem alignItems="center" key={each}>
                    <ListItemIcon>
                      <CheckIcon className={classes.listItemIcon} />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                      {each}
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid
              className={classes.trackConatainer}
              item
              md={6}
              sm={12}
              xs={12}
            >
              <Box
                className={classes.trackSectionImage}
                display="flex"
                justifyContent={
                  { sm: "center", xs: "center" }[width] || "flex-end"
                }
              >
                <StaticImage
                  alt="Sales Pipeline Management"
                  className={classes.trackImg}
                  placeholder="blurred"
                  src="../images/v2/aircall/track-Image-2x.png"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className={classes.requestDemoRoot} py={10} textAlign="center">
        <Container>
          <Box alignItems="center" display="flex" justifyContent="center">
            <HeaderTypography
              className={classes.driveSalesText}
              color="common.white"
              component="h2"
              fontSize={35}
              fontWeight={600}
              maxWidth={864}
              my={0}
              textAlign="center"
            >
              Drive sales with the power of all-in-one sales CRM
            </HeaderTypography>
          </Box>
          <Box mt={6} textAlign="center">
            <WhiteButton
              color="primary"
              mb={6}
              onClick={executeScroll}
              size="large"
              variant="contained"
            >
              Book a FREE Demo
            </WhiteButton>
          </Box>
        </Container>
      </Box>

      <Footer showWidget={false} />
    </React.Fragment>
  );
};

export default AircallPage;
